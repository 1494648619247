import { Server } from "./server";

const HUB_SERVER_URL = process.env.REACT_APP_EQHUB_SERVER_URL;

const baseURL = `${HUB_SERVER_URL}`;

const config = {
  withCredentials: true,
  crossDomain: true,
  credentials: "include",
  timeout: 4000,
};

/** notice server */
export const noticeServer = new Server(baseURL);

export const showServerLogs = () => {
  noticeServer.serverResponseInterceptor();
};
