export type ApiErrorType = "API";
export const ApiErrorType = "API";

export type ApiError = {
  type: ApiErrorType;
  data: {
    baseURL: string | undefined;
    path: string | undefined;
    message: string | undefined;
    httpStatus: string | undefined;
    serverStatusCode: string | undefined;
    data: string | undefined;
  };
};
export const isApiError = (error: any): error is ApiError => {
  return error.type === ApiErrorType;
};
