import axios from 'axios';
import {ApiErrorType, ApiError} from './type';

// Each server should have different apiErrorCatcher
export const apiErrorCatcher = (e: unknown) => {
  let apiError: ApiError = {
    type: ApiErrorType,
    data: {
      baseURL: undefined,
      path: undefined,
      message: undefined,
      httpStatus: undefined,
      serverStatusCode: undefined,
      data: undefined,
    },
  };
  if (axios.isAxiosError(e)) {
    apiError = {
      ...apiError,
      data: {
        ...(e?.response?.config?.baseURL && {
          baseURL: e?.response?.config?.baseURL,
        }),
        ...(e?.response?.config?.url && {path: e?.response?.config?.url}),
        ...(e?.response?.data?.message && {
          message: e?.response?.data?.message,
        }),
        ...(e?.response?.status && {
          httpStatus: e?.response?.status?.toString(),
        }),
        ...(e?.response?.data?.serverStatusCode && {
          serverStatusCode: e?.response?.data?.serverStatusCode,
        }),
        ...(e?.response?.data?.data && {
          data: e?.response?.data?.data,
        }),
      },
    };
  }
  return apiError;
};
