import React, { useState } from "react";
import Input from "../../../common/atom/input/Input";
import "./Section6.scss";
import TextArea from "../../../common/atom/input/TextArea";
import Link_Button_Grey from "../../../common/asset/images/Link_Button_Grey.png";

function Section6(props) {
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");

    // const sendQuestionToEQBR = async() => {
    //     if(Boolean(email)){
    //
    //     }else{
    //
    //     }
    // }

    return (
        <div id="Section6">
            <div className="section6-text-container">
                <div className="section6-title">Contact Us</div>
                <div className="section6-sub-title">
                    {"Got a question? We’d love to hear from you.\n" +
                        "Send us a message and we’ll respond as soon as possible."}
                </div>
            </div>
            <div className="section6-contact-container">
                <div className="section6-input-container">
                    <Input
                        type="text"
                        className="input-contact"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="section6-textarea-container">
                    <TextArea
                        className="textarea-contact"
                        placeholder={
                            "Please write your question or a description of the problem you’re trying to solve here. "
                        }
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <div className="list-btn gray">
                        <div className="round">
                            <div id="cta">
                                <span className="arrow primera next "></span>
                                <span className="arrow segunda next "></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section6;
