import React from "react";
import Whisper_Card_Image from "../../../../common/asset/images/Section3_Whisper_Card_Image.png";
import "./Section3WhisperCard.scss";
import whiteLinkBtn from "../../../../common/asset/images/Link_Button_White.png";

function Section3WhisperCard(props) {
  return (
    <div id="Section3WhisperCard">
      <div className="section3-whisper-card-image-container">
        <img className="section3-whisper-card-image" src={Whisper_Card_Image} alt="" />
      </div>
      <div className="section3-whisper-card-wrapper">
        <div className="section3-whisper-card-text-wrapper">
          <div className="section3-whisper-card-text-main">Super App for Web 3.0</div>
          <div className="section3-whisper-card-text-sub">Whisper Wallet</div>
        </div>
        <div className="list-btn white" onClick={() => window.open("https://whispermsg.com/")}>
          <div className="round">
            <div id="cta">
              <span className="arrow primera next "></span>
              <span className="arrow segunda next "></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3WhisperCard;
