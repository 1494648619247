import Section2CardImage1 from "../../common/asset/images/Section2_Card_Image1.png";
import Section2CardImage2 from "../../common/asset/images/Section2_Card_Image2.png";
import Section2CardImage3 from "../../common/asset/images/Section2_Card_Image3.png";

export const section2CardData = [
    {
        title: 'FAST & STABLE',
        image: Section2CardImage1,
        mainText: 'Proprietary \n' + 'Blockchain Engine',
        description: 'Solutions based on an advanced 3rd generation blockchain engine, Equilibrium.'
    },
    {
        title: 'REDUCED TIME & COST',
        image: Section2CardImage2,
        mainText: 'Comprehensive\n' + 'Development Tools',
        description: 'Lowers entry barriers with simple UI and provides various network and service build-up methods.'
    },
    {
        title: 'ENHANCED SCALABILITY & VALUE',
        image: Section2CardImage3,
        mainText: 'Service\n' + 'Promotion Tools',
        description: 'Provides dAPP-specific application market and marketing spaces to allow amicable operation of services.'
    }
]