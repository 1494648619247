import { BrowserRouter } from "react-router-dom";
import RootRouter from "./view/router/RootRouter";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <BrowserRouter>
        <RootRouter />
        <Toaster />
      </BrowserRouter>
    </>
  );
}

export default App;
