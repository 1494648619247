import React from "react";
import "./Paging.scss";
import prevIcon from "../../../common/asset/images/Icon_paging_prev.png";
import nextIcon from "../../../common/asset/images/Icon_paging_next.png";
import { useResponsive } from "../../../common/styles/Responsive";
import { useNavigate } from "react-router-dom";

const Paging = (props) => {
  const navigate = useNavigate();
  const { currentPage, totalPage, setCurrentPage, isGetList } = props;
  const { isMobile } = useResponsive();
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPage;

  const navigateCurrentPage = (page) => {
    if (page < 1 || page > totalPage) {
      return;
    }

    setCurrentPage(page);
    navigate(`/notices?page=${page}`);
  };

  return (
    <div className="paging">
      {!isMobile && (
        <div
          className={`paging-box ${isFirstPage ? "disabled" : ""}`}
          onClick={isFirstPage ? null : () => navigateCurrentPage(1)}
        >
          <div className="paging-label">First</div>
        </div>
      )}
      <div
        className={`paging-box ${isFirstPage ? "disabled" : ""}`}
        onClick={
          isFirstPage ? null : () => navigateCurrentPage(currentPage - 1)
        }
      >
        <div className="paging-icon">
          <img src={prevIcon} className="paging-icon-src" alt="" />
        </div>
      </div>
      <div className="paging-box">
        <div className="paging-current">
          {`Page ${isGetList ? currentPage : 0} of ${totalPage}`}
        </div>
      </div>
      <div
        className={`paging-box ${isLastPage ? "disabled" : ""}`}
        onClick={isLastPage ? null : () => navigateCurrentPage(currentPage + 1)}
      >
        <div className="paging-icon">
          <img src={nextIcon} className="paging-icon-src" alt="" />
        </div>
      </div>
      {!isMobile && (
        <div
          className={`paging-box ${isLastPage ? "disabled" : ""}`}
          onClick={isLastPage ? null : () => navigateCurrentPage(totalPage)}
        >
          <div className="paging-label">Last</div>
        </div>
      )}
    </div>
  );
};

export default Paging;
