import {
  getNoticeHtmlContentParser,
  getNoticeListParser,
  getNoticeTypesParser,
} from "parser/noticeParser";
import { actionController } from "./utils/actionController";
import { getNoticeDetailParser } from "../parser/noticeParser";

export const getNoticeTypesAction = () =>
  actionController(async () => {
    const result = await getNoticeTypesParser();
    return result;
  });

export const getNoticeListAction = ({ offset, limit }) =>
  actionController(async () => {
    const result = await getNoticeListParser({ offset, limit });
    return result;
  });

export const getNoticeDetailAction = (noticeId) =>
  actionController(async () => {
    const result = await getNoticeDetailParser(noticeId);
    return result;
  });

export const getNoticeHtmlContentAction = (contentUrl) =>
  actionController(async () => {
    const result = await getNoticeHtmlContentParser(contentUrl);
    return result;
  });
