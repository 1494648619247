import React from 'react';
import Section5Card from "../molecules/section5/Section5Card";
import './Section5.scss'
function Section5(props) {
    return (
        <div id='Section5'>
            <Section5Card/>
        </div>
    );
}

export default Section5;