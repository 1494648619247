import Link_Button_Blue from "../../common/asset/images/Link_Button_Blue.png";

export const Section4SmallCardData = [
    {
        title: 'EQ GATEWAY',
        mainText: 'Port your digital assets freely \n' + 'between networks',
        linkButton: Link_Button_Blue
    },
    {
        title: 'EQ LINKER',
        mainText: 'Port your digital assets freely \n' + 'within a network',
        linkButton: Link_Button_Blue
    }
]