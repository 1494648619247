import Link_Button_White from "../../common/asset/images/Link_Button_White.png";
import Link_Button_Blue from "../../common/asset/images/Link_Button_Blue.png";

export const Section3SmallCardData = [
  {
    title: (
      <div>
        <div>THE STRONG</div>
        <div style={{ color: "#1D74FF" }}>MULTICHAIN</div>
        <div>WALLET</div>
      </div>
    ),
    description: "The commercially viable blockchain engine",

    backgroundColor: "#1B2A3D",
    color: "#ffffff",
  },
  {
    title: "EQ Launchpad",
    description: "EQ Launchpad helps to induce investments from investors by showcasing actual services that runs on a blockchain network.",
    linkImage: Link_Button_Blue,
    backgroundColor: "#ffffff",
    color: "#1B2A3D",
  },
];
