import React from "react";
import telegramIcon from "../../asset/images/Telegram_Icon.png";
import youtubeIcon from "../../asset/images/Youtube_Icon.png";
import mediumIcon from "../../asset/images/Medium_Icon.png";
import linkinIcon from "../../asset/images/LinkedIn-Icon.png";
import twitterIcon from "../../asset/images/Twitter_Icon.png";
import "./Footer.scss";

export const linkList = [
  {img: telegramIcon, url: "https://t.me/officialeqbr"},
  {img: linkinIcon, url: "https://www.linkedin.com/company/eqbr/"},
  {img: mediumIcon, url: "https://medium.com/eqbr"},
  {img: twitterIcon, url: "https://twitter.com/OfficialEqbr"},
  {
    img: youtubeIcon,
    url: "https://www.youtube.com/channel/UCwjHVefVkToqi9FfGTlFakw",
  },
];

export const openPage = (url) => {
  if (url) {
    window.open(url);
  }
  if (!url) {
  }
};

function Footer() {
  const footerInfo = [
    {
      category: "Company",
      subMenu : [
        {name: "About", type: 0, url: "https://eqbr.com/"},
        {name: "Features", type: 0, url: "https://eqbr.com/eqbr/introduction"},
        {name: "Career", type: 1, url: ""},
        {
          name: "Contact",
          type: 0,
          url : "https://share.hsforms.com/16wN8H8YCS_OwnCaC8XTtPQdynr2",
        },
        {name: "Press", type: 0, url: "https://eqbr.com/newsroom"},
      ],
    },
    {
      category: "Products",
      subMenu : [
        {name: "Equilibrium", type: 0, url: "https://eqbr.com/tech-service/tech"},
        {name: "EQ Hub", type: 0, url: "https://eqbr.com/tech-service/service"},
        {name: "API", type: 0, url: "https://docs.eqhub.eqbr.com"},
      ],
    },
  ];


  return (
    <div id="Footer">
      <div className="footer-content-wrapper">
        <div className="footer-info-wrapper">
          {footerInfo.map((el, index) => {
            return (
              <div className="footer-info" key={index}>
                <div className="footer-info-title">{el.category}</div>
                {el.subMenu.map((subMenuEl, subMenuIndex) => {
                  return (
                    <div
                      className={`footer-info-sub-menu ${subMenuEl.type === 1}`}
                      key={subMenuIndex}
                      onClick={() => {
                        openPage(subMenuEl.url);
                      }}
                    >
                      {subMenuEl.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="sns-link-wrapper">
          {linkList.map((el, index) => {
            return (
              <img
                src={el.img}
                alt=""
                className="sns-link"
                key={index}
                onClick={() => {
                  openPage(el.url);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="eqbr-info-wrapper">
        <div className="eqbr-info">ⓒ 2023. EQBR Holdings all rights reserved.</div>
        <div className="policy-wrapper">
          <div
            className="eqbr-info policy"
            onClick={() => {
              openPage("https://s3.ap-northeast-2.amazonaws.com/resource-files.eqbr.com/EQHUB/EQ+HUB+_+Terms+of+service.pdf");
            }}
          >
            Terms of Use
          </div>
          {/* <div
           className="eqbr-info policy"
           onClick={() => {
           openPage();
           }}
           >
           Cookie Policy
           </div> */}
          <div
            className="eqbr-info policy"
            onClick={() => {
              openPage("https://s3.ap-northeast-2.amazonaws.com/resource-files.eqbr.com/EQBR/EQBRholdings+_+Privacy+Policy.pdf");
            }}
          >
            Privacy Policy
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
