import React from 'react';
import ReactDOM from 'react-dom';
import './view/common/styles/reset.scss'
import './index.scss';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

