import React from "react";
import temp from "../../../../common/asset/images/Section2_Card_Image1.png";
import "./Section2Card.scss";

function Section2Card({ cardData }) {
    return (
        <div id="Section2Card">
            <div className="section2-card-container">
                <div className="section2-card-title">{cardData.title}</div>
                <div className="section2-card-content">
                    <div className="section2-card-main-content">
                        <img
                            className="section2-card-image"
                            src={cardData.image}
                            alt={"card"}
                        />
                        <div className="section2-card-main-text">
                            {cardData.mainText}
                        </div>
                    </div>
                    <div className="section2-card-description">
                        {cardData.description}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section2Card;
