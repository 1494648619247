import React from "react";
import "./Section1.scss";

function Section1(props) {
  const openEqhub = () => {
    window.open(process.env.REACT_APP_EQHUB_URL);
  };
  const openAPIDocs = () => {
    window.open(process.env.REACT_APP_API_DOCS_URL);
  };

  return (
    <div id="Section1">
      <div className="section1-text-container">
        <div className="section1-main-text-wrapper">
          <div className="section1-main-text">EQBR One-Stop</div>
          <div className="section1-main-text gradient">Blockchain Platform</div>
        </div>
        <div className="section1-sub-text">The ultimate Web 3.0 toolcase</div>
        <div className="section1-btn-wrapper">
          <div className="section1-btn" onClick={openEqhub} data-tag-index="main" data-tag-target="start">
            <div className="section1-btn-text" data-tag-index="main" data-tag-target="start">
              Start Building
            </div>
          </div>
          <div className="section1-btn no-bg" onClick={openAPIDocs} data-tag-index="main" data-tag-target="API Docs">
            <div className="section1-btn-text" data-tag-index="main" data-tag-target="API Docs">
              Read API Docs
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
