import React, { useState, useEffect } from "react";
import "./NoticeDetail.scss";
import backIcon from "../../../common/asset/images/Iocn_back.png";
import { useNavigate, useParams } from "react-router-dom";
import { getNoticeDetailAction, getNoticeHtmlContentAction } from "../../../../action/noticeAction";
import { ParsedNotice } from "reducer/clientType/noticeType";

const NoticeDetail = () => {
  const navigate = useNavigate();
  const noticeId = useParams().id;

  const [noticeDetail, setNoticeDetail] = useState<ParsedNotice | any>({});
  const [htmlText, setHtmlText] = useState("");

  const getHtml = async (notice) => {
    const { result, error } = await getNoticeHtmlContentAction(notice.content_url);
    if (error) {
    }
    setHtmlText(result);
  };

  const getNoticeDetail = async (noticeId) => {
    const { result, error } = await getNoticeDetailAction(noticeId);
    if (error) {
      return;
    }
    getHtml(result);
    setNoticeDetail(result);
  };

  useEffect(() => {
    getNoticeDetail(noticeId);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="NoticeDetail">
      <div className="notice-detail">
        <div className="notice-detail-back" onClick={() => navigate(-1)}>
          <img src={backIcon} className="notice-detail-back-icon" alt="" />
          <div className="notice-detail-back-label">Back to list</div>
        </div>
        <div className="notice-detail-header">
          <div className="notice-detail-header-type">{noticeDetail.noticeTypeName}</div>
          <div className="notice-detail-header-title">{noticeDetail.title}</div>
          <div className="notice-detail-header-date">{noticeDetail.parsedDate}</div>
        </div>
        {htmlText?.length > 0 ? (
          <div
            className="notice-detail-body"
            dangerouslySetInnerHTML={{
              __html: htmlText?.length > 0 ? htmlText : "",
            }}
          ></div>
        ) : (
          <div className="notice-detail-body">{noticeDetail.content} </div>
        )}
      </div>
    </div>
  );
};

export default NoticeDetail;
