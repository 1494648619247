import React from "react";
import "./Section3SmallCard.scss";
import toast from "react-hot-toast";

function Section3SmallCard({ cardData }) {
  const comingSoonToast = () => {
    toast("Coming soon!", {
      icon: "👏",
    });
  };
  return (
    <div
      id="Section3SmallCard"
      style={{
        backgroundColor: `${cardData.backgroundColor}`,
        color: `${cardData.color}`,
      }}
    >
      <div className="section3-small-card-text-container">
        <div className="section3-small-card-title">{cardData.title}</div>
        <div className="section3-small-card-description">{cardData.description}</div>
      </div>
      {cardData.linkImage ? (
        <div className="list-btn" onClick={comingSoonToast}>
          <div className="round">
            <div id="cta">
              <span className="arrow primera next "></span>
              <span className="arrow segunda next "></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="section3-btn" onClick={comingSoonToast}>
          Download
        </div>
      )}
    </div>
  );
}

export default Section3SmallCard;
