import React from "react";
import {Outlet} from "react-router-dom";
import Header from "../common/template/header/Header";
import Footer from "../common/template/footer/Footer";

function Layout(props) {
  const layoutStyle = {
    display      : "flex",
    flexDirection: "column",
    alignItems   : "center",
    width        : "100%",
    paddingTop   : 70
    // padding: "120px",
  };
  return (
    <>
      <Header/>
      <main style={layoutStyle}>
        <Outlet/>
      </main>
      <Footer/>
    </>
  );
}

export default Layout;
