import Link_Button_Blue from "../../common/asset/images/Link_Button_Blue.png";
import Section3_DApp_Card_Image from "../../common/asset/images/Section3_DApp_Card_Image.png";
import Section3_Airdrop_Card_Image from "../../common/asset/images/Section3_Airdrop_Card_Image.png";

export const Section3LargeCardData = [
    {
        title: "EQ SWAP",
        description: `Trade, and grow with EQ Swap's intelligent cross-chain DEX featuring concentrated liquidity on the high-performance Equilibrium.`,
        linkButton: Link_Button_Blue,
        cardImage: Section3_DApp_Card_Image,
    },
    {
        title: "EQ Airdrop",
        description:
            "EQ Airdrop Provides spaces for holding and managing Airdrop events for individual network and service promotions.",
        linkButton: Link_Button_Blue,
        cardImage: Section3_Airdrop_Card_Image,
    },
];
