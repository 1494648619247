import React from "react";
import "./Main.scss";
import Section1 from "../organisms/Section1";
import Section2 from "../organisms/Section2";
import Section3 from "../organisms/Section3";
import Section4 from "../organisms/Section4";
import Section5 from "../organisms/Section5";
import Section6 from "../organisms/Section6";
import bgImg from "../../../common/asset/images/Background_Img.png";

function Main(props) {
  return (
    <div id="Main">
      <img src={bgImg} alt="" className="background" />
      <div className="bg-circle1" />
      <div className="bg-circle2" />
      <div className="bg-circle3" />
      <div className="bg-circle4" />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/*<Section6 />*/}
    </div>
  );
}

export default Main;
