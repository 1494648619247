import {useMediaQuery} from "react-responsive";

const TABLET_MIN_WIDTH = 768;
const DESKTOP_MIN_WIDTH = 1440;

export const useResponsive = () => {
  const isMobile = useMediaQuery({
    query: `(max-width:${TABLET_MIN_WIDTH - 1}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width:${TABLET_MIN_WIDTH }px) and (max-width:${DESKTOP_MIN_WIDTH - 1}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width:${DESKTOP_MIN_WIDTH}px)`,
  });

  return {
    isMobile,
    isTablet,
    isDesktop
  }
}
