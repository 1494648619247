import { noticeServer } from "../utils/serverInstances";
import { makeQuery } from "../utils/makeQuery";
import { Notice, NoticeType } from "reducer/clientType/noticeType";
import axios from "axios";

const api_version = process.env.REACT_APP_NOTICE_API_VERSION;
const base_path = process.env.React_APP_API_BASE_PATH;

const getNoticeTypes = async (): Promise<getNoticeTypesReponse> => {
  const result = await noticeServer.get({
    path: `${api_version}${base_path}notice-types`,
  });
  return result;
};

type getNoticeTypesReponse = NoticeType[];

const getNoticeType = async (
  request: getNoticeRequest
): Promise<getNoticeResponse> => {
  const result = await noticeServer.get({
    path: `${api_version}${base_path}notice-types/${request.params.noticeTypeId}`,
  });
  return result;
};

type getNoticeRequest = {
  params: {
    noticeTypeId: number;
  };
};

type getNoticeResponse = NoticeType;

const getNoticeList = async (
  request: getNoticeListRequest
): Promise<getNoticeListResponse> => {
  const result = await noticeServer.get({
    path: `${api_version}${base_path}notices`,
    query: makeQuery(request.query),
  });
  return result;
};

type getNoticeListRequest = {
  query: {
    offset: number;
    limit: number;
    order: string;
  };
};

type getNoticeListResponse = {
  count: number;
  rows: Notice[];
};

const getNoticeDetail = async (
  request: getNoticeDetailRequest
): Promise<getNoticeDetailResponse> => {
  const result = await noticeServer.get({
    path: `${api_version}${base_path}notices/${request.param}`,
  });
  return result;
};

type getNoticeDetailRequest = {
  param: {
    noticeId: number;
  };
};

type getNoticeDetailResponse = Notice;

const getNoticeHtmlContent = async (contentUrl: string) => {
  const result = await axios.get(`${contentUrl}`);
  return result;
};

export {
  getNoticeTypes as getNoticeTypesFromServer,
  getNoticeType as getNoticeTypeFromServer,
  getNoticeList as getNoticeListFromServer,
  getNoticeDetail as getNoticeDetailFromServer,
  getNoticeHtmlContent as getNoticeHtmlContentFromServer,
};
