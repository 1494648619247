import {
  getNoticeDetailFromServer,
  getNoticeHtmlContentFromServer,
  getNoticeListFromServer,
  getNoticeTypeFromServer,
  getNoticeTypesFromServer,
} from "server/api/noticeAPI";
import { parserController } from "./utils/parserController";
import { parseDate } from "../utils/dateUtils";
import { matchType } from "../utils/typeUtils";

export const getNoticeTypesParser = () =>
  parserController(async () => {
    const result = await getNoticeTypesFromServer();
    return result;
  });

export const getNoticeTypeParser = () => parserController(async () => {});

export const getNoticeListParser = ({ offset, limit }) =>
  parserController(async () => {
    const [noticeList, typeList] = await Promise.all([
      getNoticeListFromServer({ query: { offset, limit, order: "DESC" } }),
      getNoticeTypesFromServer(),
    ]);

    const { rows, count } = noticeList;

    const parsedNoticeList = rows.map((el) => {
      return {
        ...el,
        parsedDate: parseDate(el.posted_at),
        noticeTypeName: matchType(typeList, el),
      };
    });

    return {
      rows: parsedNoticeList,
      count,
    };
  });

export const getNoticeDetailParser = (noticeId) =>
  parserController(async () => {
    const result = await getNoticeDetailFromServer({
      param: noticeId,
    });
    const type = await getNoticeTypeFromServer({
      params: {
        noticeTypeId: result.notice_type_id,
      },
    });

    result["parsedDate"] = parseDate(result.posted_at);
    result["noticeTypeName"] = type.name;
    return result;
  });

export const getNoticeHtmlContentParser = (contentUrl) =>
  parserController(async () => {
    const result = await getNoticeHtmlContentFromServer(contentUrl);
    if (result.status !== 200) {
      return;
    }
    return result.data;
  });
