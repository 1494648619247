import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { apiErrorCatcher } from "./apiErrorCatcher";
import { consoleError } from "./console";

type APIRequest = {
  baseURL?: string;
  path: string;
  query?: string;
  data?: object;
  config?: object;
};

export class Server {
  serverInstance: AxiosInstance;

  constructor(baseURL?: string, timeout: number = 4000, withCredentials: boolean = true) {
    this.serverInstance = axios.create({
      baseURL,
      withCredentials,
      timeout,
    });
  }
  updateRequestHeader(requestHeader: any) {
    this.serverInstance.defaults.headers.common = requestHeader;
  }

  serverResponseInterceptor() {
    this.serverInstance.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      function (error: AxiosError) {
        if (process.env.NODE_ENV !== "production") {
          consoleError(error);
        }
        throw error;
      }
    );
  }

  getBaseURL() {
    return this.serverInstance.defaults.baseURL;
  }

  async get({ baseURL, path, query = "", config }: APIRequest) {
    try {
      return (await this.serverInstance.get(path + query, { ...config, baseURL })).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async post({ baseURL, path, query = "", data, config }: APIRequest) {
    try {
      return (await this.serverInstance.post(path + query, data, { ...config, baseURL })).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async delete({ baseURL, path, query = "", config }: APIRequest) {
    try {
      return (await this.serverInstance.delete(path + query, { ...config, baseURL })).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async put({ baseURL, path, query = "", data, config }: APIRequest) {
    try {
      return (await this.serverInstance.put(path + query, data, { ...config, baseURL })).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }
}
