import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Main from "../service/main/page/Main";
import NoticeList from "../service/notice/page/NoticeList.tsx";
import NoticeDetail from "../service/notice/page/NoticeDetail";

function RootRouter(props) {
  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route index element={<Main />} />
        <Route path="notices" element={<NoticeList />}></Route>
        <Route path="notice/:id" element={<NoticeDetail />} />
      </Route>
    </Routes>
  );
}

export default RootRouter;
