import React, { useCallback, useEffect, useState } from "react";
import "./Header.scss";
import EQ_Hub_Logo from "../../asset/images/EQ_Hub_Logo.png";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../styles/Responsive";
import MenuIcon from "../../asset/images/Icon_menu.png";
import MenuCloseIcon from "../../asset/images/Icon_menu_close.png";
import { linkList } from "../footer/Footer";

const EQ_HUB_URL = process.env.REACT_APP_EQHUB_URL;
const API_DOCS_URL = process.env.REACT_APP_API_DOCS_URL;

function Header(props) {
  const navigate = useNavigate();

  const { isMobile } = useResponsive();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);

  const onClickMenu = (type, path) => {
    if (type === "route") {
      navigate(path);
      return;
    }
    if (type === "link") {
      window.open(path);
      return;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobile]);

  const checkScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);

    return () => window.removeEventListener("scroll", checkScroll);
  }, [checkScroll]);

  return (
    <header
      style={{
        backgroundColor: scrollPosition < 1 ? "transparent" : "#fefefe",
        ...(isMobile && isMobileMenuOpen && { height: "100%", backgroundColor: "#ffffff" }),
      }}
    >
      <div className="header-container">
        <div className="header-logo-container">
          <img className="header-logo" src={EQ_Hub_Logo} alt="" onClick={() => onClickMenu("route", "/")} />
        </div>
        {isMobile && (
          <img
            className="mobile-menu-toggle"
            onClick={() => setIsMobileMenuOpen((prev) => !prev)}
            src={isMobileMenuOpen ? MenuCloseIcon : MenuIcon}
            alt="menu"
          />
        )}
        {!isMobile && (
          <div className="header-menu">
            <div className="header-menu-menu notice" onClick={() => onClickMenu("route", "/notices")}>
              Notice
            </div>
            <span className="header-menu-text" onClick={() => onClickMenu("link", API_DOCS_URL)}>
              API Docs
            </span>
            <div className="header-menu-menu gradient" onClick={() => onClickMenu("link", EQ_HUB_URL)}>
              Console
            </div>
          </div>
        )}
      </div>
      {isMobile && isMobileMenuOpen && <MobileMenu onClickMenu={onClickMenu} setIsMobileMenuOpen={setIsMobileMenuOpen} />}
    </header>
  );
}

export default Header;

const MobileMenu = ({ onClickMenu, setIsMobileMenuOpen }) => {
  const MOBILE_MENU = [
    {
      label: "Notice",
      type: "route",
      path: "/notices",
    },
    {
      label: "API Docs",
      type: "link",
      path: API_DOCS_URL,
    },
    {
      label: "Console",
      type: "link",
      path: EQ_HUB_URL,
    },
  ];

  const POLICY_LIST = [
    {
      label: "Terms of use",
      type: "link",
      path: "https://s3.ap-northeast-2.amazonaws.com/resource-files.eqbr.com/EQHUB/EQ+HUB+_+Terms+of+service.pdf",
    },
    {
      label: "Privacy Policy",
      type: "link",
      path: "https://s3.ap-northeast-2.amazonaws.com/resource-files.eqbr.com/EQBR/EQBRholdings+_+Privacy+Policy.pdf",
    },
  ];

  const onClickMobileMenu = (type, path) => {
    onClickMenu(type, path);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="mobile-menu">
      <div className="mobile-menu-top">
        {MOBILE_MENU.map((el) => (
          <div className="mobile-menu-top-menu" onClick={() => onClickMobileMenu(el.type, el.path)}>
            {el.label}
          </div>
        ))}
      </div>
      <div className="mobile-menu-bottom">
        <div className="mobile-sns-link-wrapper">
          {linkList.map((el, index) => {
            return (
              <img
                src={el.img}
                alt=""
                className="mobile-sns-link"
                key={index}
                onClick={() => {
                  onClickMenu("link", el.url);
                }}
              />
            );
          })}
        </div>
        <div className="mobile-policy-wrapper">
          {POLICY_LIST.map((el) => (
            <div
              className="mobile-policy"
              onClick={() => {
                onClickMenu("link", el.path);
              }}
            >
              {el.label}
            </div>
          ))}
        </div>
        <div className="mobile-copyright">ⓒ 2023. EQBR Holdings all rights reserved.</div>
      </div>
    </div>
  );
};
