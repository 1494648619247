import React from "react";
import "./Section3.scss";
import Section3SmallCard from "../molecules/section3/Section3SmallCard";
import { Section3SmallCardData } from "../../../data/main/section3SmallCardData";
import Section3WhisperCard from "../molecules/section3/Section3WhisperCard";
import Section3LargeCard from "../molecules/section3/Section3LargeCard";
import { Section3LargeCardData } from "../../../data/main/section3LargeCardData";

function Section3(props) {
  return (
    <div id="Section3">
      <div className="section3-title-wrapper">
        <div className="section3-title-main">EQ Hub, your own Web 3.0 world</div>
        <div className="section3-title-sub">The beginning of your journey into blockchain ecosystem</div>
      </div>
      <div className="section3-card-container">
        <div className="section3-small-card-inner-container">
          {Section3SmallCardData.map((cardData, index) => {
            return <Section3SmallCard key={`card-small-3-${index}`} cardData={cardData} />;
          })}
        </div>
        <Section3WhisperCard />
        <div className="section3-small-card-inner-container">
          {Section3LargeCardData.map((cardData, index) => {
            return <Section3LargeCard key={`card-large-3-${index}`} cardData={cardData} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Section3;
