import React from "react";
import "./Section4LargeCard.scss";
import Link_Button_White from "../../../../common/asset/images/Link_Button_White.png";

function Section4LargeCard(props) {
  const openEQField = () => {
    window.open(process.env.REACT_APP_EQ_FIELD_URL)
  }
  return (
    <div id="Section4LargeCard">
      <div className="section4-large-card-title">EQ FIELD</div>
      <div className="section4-large-card-content">
        <div className="section4-large-card-main-text" >
          Find block and transaction information in real time
        </div>
        <div className="list-btn white black">
          <div className="round" onClick={openEQField}>
            <div id="cta">
              <span className="arrow primera next "></span>
              <span className="arrow segunda next "></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4LargeCard;
