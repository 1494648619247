import React, { useState, useEffect, useMemo } from "react";
import "./NoticeList.scss";
import eqLogo from "../../../common/asset/images/notice-header-icon.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Paging from "../component/Paging";
import { useResponsive } from "../../../common/styles/Responsive";
import { getNoticeListAction } from "../../../../action/noticeAction";
import { ParsedNotice } from "reducer/clientType/noticeType";
import qs from "qs";

const NOTICE_LIST_LIMIT = 20;

const NoticeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { page } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const parsedPage = page ? +page : null;

  const { isMobile, isTablet, isDesktop } = useResponsive();
  const [isMouseOver, setIsMouseOver] = useState(0);
  const [noticeList, setNoticeList] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState<number | any>(parsedPage ? parsedPage : 1);
  const offset = useMemo(() => {
    return (currentPage - 1) * NOTICE_LIST_LIMIT;
  }, [currentPage]);

  const [isGetList, setIsGetList] = useState(false);

  const calculateTotalPage = (totalpage: number) => {
    let tempPage = Math.floor(totalpage / NOTICE_LIST_LIMIT);
    tempPage = totalpage % NOTICE_LIST_LIMIT > 0 ? tempPage + 1 : tempPage;
    setTotalPage(tempPage);
  };

  const getNoticesList = async () => {
    const { result, error } = await getNoticeListAction({
      offset,
      limit: NOTICE_LIST_LIMIT,
    });
    if (error) {
      setNoticeList([]);
      return;
    }
    calculateTotalPage(result.count);
    setNoticeList(result.rows);
    setIsGetList(true);
  };

  useEffect(() => {
    getNoticesList();
  }, [offset]);

  useEffect(() => {
    if (!parsedPage) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(parsedPage);
  }, [parsedPage]);

  return (
    <div id="NoticeList">
      <div className="notice-background-circle one" />
      <div className="notice-background-circle two" />
      {!isMobile && <div className="notice-background-circle three" />}

      <div className="notice-header-wrapper">
        <div className="notice-header-icon-wrapper">
          <img src={eqLogo} alt="icon" className="notice-header-icon" />
        </div>
        <div className="notice-header-title">Check out the latest news from EQ hub</div>
        <div className="notice-header-description">Notice</div>
      </div>
      <div className="notice-list">
        <div className="notice-list-header-row">
          <div className="notice-list-header type">Type</div>
          <div className="notice-list-header title">Title</div>
          <div className="notice-list-header date">Date</div>
        </div>
        {noticeList.map((notice: ParsedNotice) => {
          return (
            <div
              className={`notice-list-row ${isMouseOver === notice.notice_id ? "hover" : ""}`}
              onMouseEnter={() => setIsMouseOver(notice.notice_id)}
              onMouseLeave={() => setIsMouseOver(0)}
              key={notice.notice_id}
              onClick={() => navigate(`/notice/${notice.notice_id}`)}
            >
              <div className="notice-list-item type">{notice.noticeTypeName}</div>
              <div className={`notice-list-item title ${isMouseOver === notice.notice_id ? "hover" : ""}`}>{notice.title}</div>
              <div className="notice-list-item date">{notice.parsedDate}</div>
            </div>
          );
        })}
      </div>
      <div className="paging-wrapper">
        <Paging currentPage={currentPage} totalPage={totalPage} setCurrentPage={setCurrentPage} isGetList={isGetList} />
      </div>
    </div>
  );
};

export default NoticeList;
