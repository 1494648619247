import React from "react";
import "./Section5Card.scss";
import Section5_Card_Image from "../../../../common/asset/images/Section5_Developer_Card_Image.png";
import Link_Button_White from "../../../../common/asset/images/Link_Button_White.png";

function Section5Card(props) {
  const openEqhub = () => {
    window.open(process.env.REACT_APP_EQHUB_URL)
  }
  return (
    <div id="Section5Card">
      <div className="section5-card-content">
        <div className="section5-card-content-text">
          {
            "Easily create blockchain network and develop, operate and distribute various blockchain services with EQ Developer"
          }
        </div>
        <div className="list-btn white">
          <div className="round" onClick={openEqhub}>
            <div id="cta">
              <span className="arrow primera next "></span>
              <span className="arrow segunda next "></span>
            </div>
          </div>
        </div>
      </div>
      <img
        className="section5-card-image"
        src={Section5_Card_Image}
        alt=""
      />
    </div>
  );
}

export default Section5Card;
